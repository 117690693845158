import axios from 'axios';
import Places from 'google-places-web';

//https://api.hansentc.ca/api/cars/3?populate=*
//export const server = "https://localhost:44371/api/";
//export const bserver = "https://localhost:44371/api/";

//const corsurl = "https://cors-anywhere.herokuapp.com/"
const corsurl = '';

export const bserver = '/api/';
export const server = corsurl + 'https://api.yootechnologies.com/api/';
export const imgServer = 'https://api.yootechnologies.com';
export const token =
  '447868ffa3fce59d85d3f48673f1733171e6e02bc40c8c9fadcff8ffc8492147f2a13c64796005d75eb2fd7e666d14b7c34962c8a65733db70dbf3d4b05e288475fbd8817542f746f1490b084b677cea65c6169aeda1c06c56f9dadc0399677b9dfca129053b0ab78f11acb5d57f620ca519cbc44ceeb2ca163a1b89ca86fb53';

const googleAPIKey = 'AIzaSyBVMvX41R_NPOlPtCFOxKv8pvsw3w_rfFc';
const placeId = 'ChIJAToILfEVK4gROs8I8ljRF58';

const googleURL =
  corsurl +
  'https://maps.googleapis.com/maps/api/place/details/json?fields=&place_id=' +
  placeId +
  '&key=' +
  googleAPIKey;

Places.apiKey = googleAPIKey;

export const req = axios.create({
  baseURL: server,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${token == null ? '' : token}`,
    'Content-Type': 'application/json',
  },
});

export const breq = axios.create({
  baseURL: bserver,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${token == null ? '' : token}`,
    'Content-Type': 'application/json',
  },
});

req.interceptors.response.use(
  (response) => {
    console.log('response from interceptor');
    console.log(response);
    return response;
  },
  (error) => {
    console.log('interceptor response error ');
    console.log(error);
    return error;
  }
);

export const googlePlaceDataAPI = async () => {
  try {
    if (sessionStorage.getItem('session_GoogleData') == null) {
      console.log('calling google API');

      const resp = await axios.get(bserver + 'GoogleService');
      sessionStorage.setItem(
        'session_GoogleData',
        JSON.stringify(resp.data.result)
      );
    }
    //console.log("🚀 ~ file: config.js ~ line 52 ~ session_GoogleData", resp)
    return JSON.parse(sessionStorage.getItem('session_GoogleData'));
  } catch (error) {
    return null;
  }
};

export const getWebsiteData = async () => {
  const respData = await req(
    `salon-coupons?populate=*&pagination[pageSize]=100`
  ).catch(function (response) {});

  return JSON.parse(JSON.stringify(respData.data.data));
};

export const getWebsiteDataWithSession = async () => {
  if (sessionStorage.getItem('session_WebsiteData') == null) {
    const respData = await req(
      `salon-coupons?populate=*&pagination[pageSize]=100`
    ).catch(function (response) {});
    sessionStorage.setItem(
      'session_WebsiteData',
      JSON.stringify(respData.data.data)
    );
  }

  return JSON.parse(sessionStorage.getItem('session_WebsiteData'));
};
