import { async } from "regenerator-runtime";
import { req, imgServer, googlePlaceDataAPI, getWebsiteData } from "../js/config";
import 'web-social-share';
//import { ToHTML, ToHTMLWithFormatting } from "../js/base";
//import { async } from "regenerator-runtime";
let PageData = {};


const Initialize = async () => {
    try {
        await getPageData();
        console.log("getPageData", PageData)
        await generatePageHTML();
        await loadJavascriptNow();

        //bind to share a button

        $(".ShareAButton").on("click", function () {
            ShareFunction(); return false;
        });

    }
    catch (err) {
        console.log("🚀 ~ file: main.js:14 ~ Initialize ~ err", err)
    }

}

const ShareFunction = async () => {

    const url = document.querySelector('link[rel=canonical]') && document.querySelector('link[rel=canonical]').href
        || window.location.href;

    if (navigator.share) {
        navigator.share({
            title: 'SalonCoupons.ca website coupon share',
            text: 'Please click on the link below to view the coupon',
            url
        })
            .then(() => console.log('Shared Successfullly'))
            .catch((error) => console.log('Error on sharing', error));
    }

}


const getPageData = async () => {

    PageData = await getWebsiteData();

}
const generatePageHTML = async () => {
    await generatevelvetsaloncouponData(PageData.filter(x => x.attributes.Salon.includes(1)));
    await generatesilversaloncouponData(PageData.filter(x => x.attributes.Salon.includes(2)));
    await generatejpvelvetsaloncouponData(PageData.filter(x => x.attributes.Salon.includes(3)));
}
const generatevelvetsaloncouponData = async (CouponData) => {

    let tpd = '';
    CouponData.slice(0, 10).forEach(d => {
        tpd += `<div class="news-block-two" style="margin: 0px 15px;" >
    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
       
        <div class="image">
            <img src="images/resource/coupon.png" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
                <div class="overlay-inner">
                    <div class="content">
                        <a href="coupons.html?id=Velvet Salon&Phone=905-451-5004&Address=80 Pertosa Dr Brampton, ON L6X 5E9" class="icon flaticon-unlink"></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="lower-content">
            <ul class="post-meta">
                <li><a href="#"><span class="icon fa fa-user"></span>Velvet Salon</a></li>
                <li><a href="#"><span class="icon fa fa-calendar"></span>${d.attributes.EndDate}</a></li>
            </ul>
            <h5><a href="#">${d.attributes.Category}</a></h5>
            <h6><a href="#">${d.attributes.Type}</a></h6>
           
            <h2><a href="#">$${d.attributes.Price}</a></h2>
            
            
            
            <!-- Social Boxed -->
            <div class="social-boxed">
            <div class="social-inner">
                <span class="share-now fa fa-share-alt"></span>
                <a class="ShareAButton" }">Click me to share</a>
               
            </div>
        </div>
        </div>
    </div>
</div>`});

    document.querySelector("#coupon_velvetSalon").innerHTML = tpd;

}





const generatesilversaloncouponData = async (CouponData) => {

    let tps = '';

    CouponData.slice(0, 10).forEach(d => {

        tps += `<div class="news-block-two" style="margin: 0px 15px;" >
    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
       
        <div class="image">
            <img src="images/resource/coupon.png" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
                <div class="overlay-inner">
                    <div class="content">
                        <a href="coupons.html?id=Silver Salon&Phone=905-457-8700&Address=8975 Chinguacousy Rd Brampton, ON L6Y 0B7" class="icon flaticon-unlink"></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="lower-content">
            <ul class="post-meta">
                <li><a href="#"><span class="icon fa fa-user"></span>Silver Salon</a></li>
                <li><a href="#"><span class="icon fa fa-calendar"></span>${d.attributes.EndDate}</a></li>
            </ul>
          
            <h5><a href="#">${d.attributes.Category}</a></h5>
            <h6><a href="#">${d.attributes.Type}</a></h6>
           
            <h2><a href="#">$${d.attributes.Price}</a></h2>
            
            
            
            <!-- Social Boxed -->
            <div class="social-boxed">
            <div class="social-inner">
            <span class="share-now fa fa-share-alt"></span>
            <a class="ShareAButton" }">Click me to share</a>
           
        </div>
            </div>
        </div>
    </div>
</div>`});
    document.querySelector("#coupon_silverSalon").innerHTML = tps;
}
const generatejpvelvetsaloncouponData = async (CouponData) => {

    let tpl = '';
    CouponData.slice(0, 10).forEach(d => {
        tpl += `<div class="news-block-two" style="margin: 0px 15px;" >
    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
       
        <div class="image">
            <img src="images/coupon.png" alt="" />
            <!-- Overlay Box -->
            <div class="overlay-box">
                <div class="overlay-inner">
                    <div class="content">
                        <a href="coupons.html?id=JP's Velvet Salon&Phone=905-793-2828&Address=1098 Peter Robertson Blvd Brampton, ON L6R 3A5" class="icon flaticon-unlink"></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="lower-content">
            <ul class="post-meta">
                <li><a href="#"><span class="icon fa fa-user"></span>JP's Velvet Salon</a></li>
                <li><a href="#"><span class="icon fa fa-calendar"></span>${d.attributes.EndDate}</a></li>
            </ul>
          
            <h5><a href="#">${d.attributes.Category}</a></h5>
            <h6><a href="#">${d.attributes.Type}</a></h6>
           
            <h2><a href="#">$${d.attributes.Price}</a></h2>
            
            
            
            <!-- Social Boxed -->
            <div class="social-boxed">
            <div class="social-inner">
            <span class="share-now fa fa-share-alt"></span>
            <a class="ShareAButton" }">Click me to share</a>
           
        </div>
            </div>
        </div>
    </div>
</div>`});
    document.querySelector("#coupon_jpvelvetSalon").innerHTML = tpl;
}

const loadJavascriptNow = async () => {

    const script = document.createElement('script');
    script.src = 'js/script.js';

    //Append to the `head` element
    document.head.appendChild(script);
}


Initialize();

